const rule = [
  {
    value: 20000,
    score: 100,
    code: 1,
    level: 1,
    phase1: {
      addAPR: 0.39,
    },
    phase2: {
      addAPR: 0.39,
    },
  },
  {
    value: 46800,
    score: 200,
    code: 2,
    level: 2,
    phase1: {
      addAPR: 0.47,
    },
    phase2: {
      addAPR: 0.44,
    },
  },
  {
    value: 76800,
    score: 300,
    code: 3,
    level: 3,
    phase1: {
      addAPR: 0.53,
    },
    phase2: {
      addAPR: 0.5,
    },
  },
  {
    value: 138000,
    score: 400,
    code: 7,
    level: 4,
    phase1: {
      addAPR: 0.59,
    },
    phase2: {
      addAPR: 0.56,
    },
  },
  {
    value: 218000,
    score: 500,
    code: 12,
    level: 5,
    phase1: {
      addAPR: 0.66,
    },
    phase2: {
      addAPR: 0.62,
    },
  },
  {
    value: 288000,
    score: 600,
    code: 18,
    level: 6,
    phase1: {
      addAPR: 0.74,
    },
    phase2: {
      addAPR: 0.69,
    },
  },
  {
    value: 368000,
    score: 700,
    code: 25,
    level: 7,
    phase1: {
      addAPR: 0.82,
    },
    phase2: {
      addAPR: 0.75,
    },
  },
  {
    value: 468000,
    score: 800,
    code: 34,
    level: 8,
    phase1: {
      addAPR: 0.9,
    },
    phase2: {
      addAPR: 0.8,
    },
  },
];

const baseRule = [
  {
    value: 5000,
    addAPR: 0.05,
    score: 100,
    level: 1,
    daily: 0.68,
  },
  {
    value: 10000,
    addAPR: 0.08,
    score: 200,
    level: 2,
    daily: 2.19,
  },
  {
    value: 20000,
    addAPR: 0.09,
    score: 300,
    level: 3,
    daily: 4.93,
  },
  {
    value: 30000,
    addAPR: 0.1,
    score: 400,
    level: 4,
    daily: 8.22,
  },
  {
    value: 50000,
    addAPR: 0.12,
    score: 500,
    level: 5,
    daily: 16.44,
  },
  {
    value: 60000,
    addAPR: 0.13,
    score: 600,
    level: 6,
    daily: 21.37,
  },
  {
    value: 80000,
    addAPR: 0.14,
    score: 700,
    level: 7,
    daily: 30.68,
  },
  {
    value: 100000,
    addAPR: 0.15,
    score: 800,
    level: 8,
    daily: 41.1,
  },
];

const base2GenesisRule = [
  {
    value: 5000,
    addAPR: 0.3,
    score: 100,
    level: 1,
  },
  {
    value: 10000,
    addAPR: 0.4,
    score: 200,
    level: 2,
  },
  {
    value: 20000,
    addAPR: 0.45,
    score: 300,
    level: 3,
  },
  {
    value: 30000,
    addAPR: 0.5,
    score: 400,
    level: 4,
  },
  {
    value: 50000,
    addAPR: 0.55,
    score: 500,
    level: 5,
  },
  {
    value: 60000,
    addAPR: 0.6,
    score: 600,
    level: 6,
  },
  {
    value: 80000,
    addAPR: 0.65,
    score: 700,
    level: 7,
  },
  {
    value: 100000,
    addAPR: 0.7,
    score: 800,
    level: 8,
  },
];

module.exports = {
  getScore(index) {
    return rule[index - 1] || {};
  },
  getLevel(amount) {
    for (const item of rule) {
      if (amount == item.value) {
        return item;
      }
    }
    return null;
  },
  base2GenesisScore(index) {
    return base2GenesisRule[index - 1] || {};
  },
  base: {
    getScore(index) {
      return baseRule[index - 1] || {};
    },
    getLevel(amount) {
      for (const item of baseRule) {
        if (amount == item.value) {
          return item;
        }
      }
      return null;
    },
  },
};
